import React from "react";
import Header from "./Header";

const Home = () => {
  return (
    <div name="home">
      <Header />
    </div>
  );
};

export default Home;
